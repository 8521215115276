<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
        <ul>
          <li>Dweck, C. S., Walton, G. M., & Cohen, G. L. (2014). <em>Academic tenacity: Mindsets and skills that promote long-term learning</em>. Bill & Melinda Gates Foundation. <a href="https://ed.stanford.edu/sites/default/files/manual/dweck-walton-cohen-2014.pdf" target="_blank">ed.stanford.edu/sites/default/files/manual/dweck-walton-cohen-2014.pdf</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
